@import "_libs.scss";
@import "_checkout_page.scss";

@media (min-width: 1400px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1370px;
    }
}

%h1 {
  font-family: 'DM Serif Display', serif;
  font-size: 96px;
  line-height: 106px;
  text-transform: uppercase;
}
%h2 {
  font-family: 'DM Serif Display', serif;
  font-size: 36px;
  line-height: 40px;
}
%body1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}
$main_colour: #FBBC14;
$accent_colour: #03412C;
$background_colour: #F4CDE0;
$secondary_colour: #73C098;
$secondary_color_2: #EE2D2A;
$secondary_colour_3: #F9F1E6;



.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

body {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: $background_colour;
  color: $accent_colour;
}

main {
  flex-grow: 1;
}

footer {
  flex-shrink: 0;
}

header {
  background-color: $secondary_colour_3;
}
.header_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  ul {
    li {
      display: inline-block;
      margin-right: 47px;
      &:last-child {
        margin-right: 0px;
      }
      a {
        font-size: 16px;
        line-height: 18px;
        color: #03412C;
        text-decoration: none;
      }
    }
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ul {
      margin-left: 63px;
    }
  }
}

.show-hide {
  border: none;
  background-color: transparent;
  outline: none;
}

.main_banner {
  .for_text_block {
    position: relative;
    p {
      position: relative;
      z-index: 33;
      margin-top: 132px;
      @extend %h1;
      margin-bottom: 180px;
    }
    svg {
      position: absolute;
      &:nth-child(2) {
        display: block;
        top: -0px;
        left: 65px;
        right: 0;
        margin: 0px auto;
      }
      &:nth-child(3) {
        right: 105%;
        top: 50%;
        transform: translateY(-50%);
      }
      &:nth-child(4) {
        bottom: 0px;
        right: 130px;
        z-index: 44;
      }
    }
  }

  .for_video_block {
    border: 5px solid #FBBC14;
    border-radius: 300px 300px 0px 0px;
    width: 100%;
    height: 572px;
    box-sizing: border-box;
    margin-top: 63px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    border-bottom: none;
    video {
      max-width: 100%;
    }
  }
}

.contact_banner {
  margin-bottom: 100px;
  .for_text_block {
    .inherit_title {
      margin-top: 131px;
      @extend %h1;
      margin-bottom: 46px;
    }
    svg {
      position: absolute;
      &:nth-child(2) {
        top: 30px;
        left: 80%;
      }
      &:nth-child(3) {
        top: 135px;
        right: 100%;
      }
      &:nth-child(4) {
        left: 80%;
        top: 412px;
      }
    }
  }
  .for_video_block {
    border: 5px solid #FBBC14;
    border-radius: 300px 300px 0px 0px;
    width: 100%;
    height: 572px;
    box-sizing: border-box;
    margin-top: 63px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    video {
      max-width: 100%;
    }
  }

  .for_contact_form {
    .wpcf7-form-control-wrap {
      display: block;
      margin-bottom: 18px;
    }
    input[type=submit] {
      font-family: 'DM Serif Display', serif;
      width: 180px;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F9F1E6;
      /* main_colour */

      border: 5px solid #FBBC14;
      border-radius: 200px;
      font-size: 20px;
      line-height: 22px;

    }
    input[type=text],
    input[type=email],
    input[type=password],
    textarea {
      outline: none;
      resize: none;
      width: 100%;
      max-width: 434px;
      height: 60px;
      box-sizing: border-box;
      background: #F9F1E6;
      /* secondary_colour */
      border: 5px solid #73C098;
      border-radius: 50px;
      padding-left: 25px;
      @extend %body1;
    }
    textarea {
      padding-top: 15px;
    }
  }
}

.register_banner {
  
  .for_text_block {
    svg {
      &:nth-child(3) {
        transform: rotate(75deg);
        top: 260px;
      }
    }
  }

  .form-submit {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    input {
      margin-right: 48px;
    }
    a {
      @extend %body1;
      color: $accent_colour;
    }
  }


  .wppb-gdpr-checkbox {
    label {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @extend %body1;
    }
    input {
      position: absolute;
      left: -999999999999px;
      &:checked {
        &+.custom-checkbox {
          &::after {
            opacity: 1;
          }
        }
      }
    }

    .custom-checkbox {
      cursor: pointer;
      margin-right: 10px;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      border: 2px solid #73C098;
      box-sizing: border-box;
      background-color: $secondary_colour_3;
      position: relative;
      &::after {
        content: '';
        display: block;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 7.1194L0.875 4.29851L0 5.23881L3.5 9L11 0.940299L10.125 0L3.5 7.1194Z' fill='%23EE2D2A'/%3E%3C/svg%3E%0A");
        width: 11px;
        height: 9px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0px auto;
        opacity: 0;
        transition: 0.3s ease;
      }
    }
  }
}

.login_banner {
  .login-remember {
    display: none!important;
  }
  .login-password {
    margin-top: 19px!important;
    margin-bottom: 30px!important;
  }

  .login-submit {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    input {
      margin-right: 48px;
    }
    a {
      @extend %body1;
      color: $accent_colour;
    }
  }
}

.second_block {
  background-color: $main_colour;
  padding-top: 116px;
  padding-bottom: 82px;
  .figure_block {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 200px 200px 0px 0px;
    width: 249px;
    height: 187px;
    margin-left: auto;
    margin-right: auto;
    background-color: $background_colour;
    p {
      @extend %h1;
    }
    margin-bottom: 31px;
    &+p {
      @extend %h2;
      margin-bottom: 50px;
    }
  }

  a {
    font-family: 'DM Serif Display', serif;
    padding: 16px 58px;
    border: 5px solid #EE2D2A;
    border-radius: 200px;
    background-color: $secondary_colour_3;
    font-size: 20px;
    line-height: 22px;
    color: $accent_colour;
  }

  margin-bottom: 74px;
}


.what_we_do {
  margin-bottom: 132px;
  .inherit_title {
    @extend %h1;
    max-width: 434px;
    position: relative;
    svg {
      position: absolute;
      right: -113px;
      top: 55px;
      z-index: -1;
    }
    margin-bottom: 57px;
  }
  .inherit_descr {
    @extend %body1;
    margin-bottom: 30px;
    max-width: 567px;
  }

  a {
    font-family: 'DM Serif Display', serif;
    font-size: 20px;
    line-height: 22px;
    color: $accent_colour;
    padding: 16px 58px;
    border: 5px solid #FBBC14;
    border-radius: 200px;
    background-color: $secondary_colour_3;
    box-sizing: border-box;
    display: inline-block;
    margin-top: 47px;

  }
  .additional_container {
    position: relative;
    svg {
      position: absolute;
      right: -75px;
      bottom: -28px;
      z-index: -1;
    }
  }
  .for_video_block {
    width: 100%;
    height: 600px;
    border: 5px solid #FBBC14;
    border-radius: 300px 300px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    video {
      max-width: 100%;
    }
    margin-top: 20px;
  }
}


.how_it_work {
  margin-bottom: 132px;
  .inherit_title {
    @extend %h1;
    margin-bottom: 84px;
  }
  .inherit_descr {
    @extend %body1;
    margin-top: 30px;
    max-width: 434px;
    margin-left: auto;
  }

  .col-lg-3 {
    p {
      &:first-child {
        max-width: 93px;
        text-align: center;
        font-size: 96px;
        line-height: 106px;
        text-transform: uppercase;
        font-family: 'DM Serif Display', serif;
        margin-bottom: 30px;
        position: relative;
        svg {
          z-index: -1;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0px auto;
          display: block;
        }
        &+p {
          @extend %body1;
        }
      }
    }
  }
}


.slider_block {
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 79px;
  background-color: $main_colour;
  position: relative;
  .prev_a,
  .next_a {
    position: absolute;
    left: 118px;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 3333;
  }
  .next_a {
    left: auto;
    right: 118px;
  }
  .slide_content {
    width: 100%;
    max-width: 887px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .for_video {
      width: 340px;
      height: 480px;
      border: 5px solid #F4CDE0;
      border-radius: 300px 300px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      video {
        max-width: 100%;
      }
    }
    .for_text {
      .first_row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          @extend %h2;
        }
        margin-bottom: 54px;
      }
      .slide_descr {
        @extend %body1;
        max-width: 434px;
        margin-bottom: 63px;
      }

      a {
        display: inline-block;
        padding: 16px 58px;
        border: 5px solid #EE2D2A;
        border-radius: 200px;
        background-color: $secondary_colour_3;
        font-family: 'DM Serif Display', serif;
        font-size: 20px;
        line-height: 22px;
        color: $accent_colour;
      }
    }
  }
}

.solid_reasons {
  padding-bottom: 107px;
  .inherit_title {
    @extend %h1;
    margin-bottom: 81px;
  }

  .inherit_text_first {
    position: relative;
    @extend %body1;
    svg {
      position: absolute;
      top: 0;
      left: -75px;
    }
  }

  a {
    padding: 16px 58px;
    background: #F9F1E6;
    /* main_colour */
    color: $accent_colour;
    font-size: 20px;
    line-height: 22px;
    border: 5px solid #FBBC14;
    border-radius: 200px;
    font-family: 'DM Serif Display', serif;
    display: inline-block;
    margin-top: 74px;
  }
}


.pre_footer {
  background-color: $accent_colour;
  padding-top: 52px;
  padding-bottom: 80px;
  color: $secondary_colour_3;
  .col-lg-6 {
    p {
      &:first-child {
        margin-top: 18px;
        @extend %h2;
        margin-bottom: 50px;
        &+p {
          @extend %body1;
        }
      }
    }
  }

  a {
    padding: 16px 58px;
    background: #F9F1E6;
    /* main_colour */
    color: $accent_colour;
    font-size: 20px;
    line-height: 22px;
    border: 5px solid #FBBC14;
    border-radius: 200px;
    font-family: 'DM Serif Display', serif;
    display: inline-block;
    margin-top: 42px;
  }
}


footer {
  background-color: $secondary_colour_3;
  padding-bottom: 45px;
  .footer_left {
    a {
      display: inline-block;
    }
    div {
      &:first-child {
        margin-top: 41px;
        margin-bottom: 20px; 
      }
      &:nth-child(2) {
        a {
          color: $main_colour;
          @extend %h2;
        }
      }
    }
  }

  .footer_right {
    ul {
      li {
        display: inline-block;
        margin-right: 22px;
        &:last-child {
          margin-right: 0px;
        }
        a {
          @extend %body1;
          color: $accent_colour;
        }
      }
      &:first-child {
        margin-top: 45px;
        margin-bottom: 15px;
        &+ul {
          margin-bottom: 25px;
          &+p {
            @extend %body1;
          }
        }
      }
    }
  }
}


.pricing_banner {
  .for_video_block {
    border: 5px solid #FBBC14;
    border-radius: 300px 300px 0px 0px;
    width: 100%;
    height: 572px;
    box-sizing: border-box;
    margin-top: 63px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    border-bottom: none;
    video {
      max-width: 100%;
    }
  }

  .for_text_block {
    position: relative;
    padding-top: 178px;
    p {
      &:first-child {
        
        @extend %h2;
        max-width: 265px;
        margin-bottom: 0px;
        &+p {
          @extend %h1;
        }
      }
    }

    svg {
      position: absolute;
      &:nth-child(3) {
        top: 89px;
        left: -50px;
      }
      &:nth-child(4) {
        top: 162px;
        right: 0;
      }
      &:nth-child(5) {
        top: 385px;
        left: 150px;
        // opacity: 0;
      }
    }
  }
}


.products_container {
  background-color: $main_colour;
  padding-top: 81px;
  padding-bottom: 69px;
  margin-bottom: 97px;
  .for_products {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .product {
      box-sizing: border-box;
      position: relative;
      width: 270px;
      height: 550px;
      background: #F4CDE0;
      /* secondary_color_2 */

      border: 5px solid #EE2D2A;
      border-radius: 200px 200px 0px 0px;

      p {
        text-align: center;
        &:first-child {
          margin-top: 57px;
          margin-bottom: 0px;
          @extend %h2;
          &+p {
            @extend %h1;
            color: $secondary_color_2;
            position: relative;
            margin-bottom: 41px;
            &::after {
              content: '';
              display: block;
              width: 187px;
              height: 5px;
              background-color: $accent_colour;
              position: absolute;
              bottom: -5px;
              left: 0;
              right: 0;
              margin: 0px auto;
            }
          }
        }
      }

      ul {
        li {
          margin-left: 75px;
          @extend %body1;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0px;
          }
          position: relative;
          &::before {
            content: '';
            display: block;
            width: 23px;
            height: 12px;
            position: absolute;
            top: 4px;
            left: -31px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='23' height='12' viewBox='0 0 23 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23 0.5C23 3.54999 21.7884 6.47506 19.6317 8.63173C17.4751 10.7884 14.55 12 11.5 12C8.45001 12 5.52494 10.7884 3.36827 8.63173C1.2116 6.47506 4.60536e-07 3.54999 0 0.500002L11.5 0.5H23Z' fill='%2373C098'/%3E%3C/svg%3E%0A");
          }
        }
      }

      a {
        position: absolute;
        left: 0;
        right: 0;
        display: inline-flex;
        margin: 0px auto;
        // padding: 16px 58px;
        width: 150px;
        height: 54px;
        justify-content: center;
        align-items: center;
        bottom: 37px;
        background: #F9F1E6;
        /* main_colour */
        border: 5px solid #FBBC14;
        border-radius: 200px;
        font-family: 'DM Serif Display', serif;
        font-size: 20px;
        line-height: 22px;
        color: $accent_colour;
      }
    }

    .product_custom {
      p {
        &:first-child {
          &+p {
            @extend %h2;
            margin-top: 33px;
            padding-bottom: 43px;
            &+p {
              @extend %body1;
              margin-left: 38px;
              // margin-right: 27px;
              max-width: 201px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}


.faq_banner {
  .for_text_block {
    svg {
      &:nth-child(2) {
        top: 90px;
        left: 248px;
      }
      &:nth-child(3) {
        top: 70px;
      }
      &:nth-child(4) {
        left: 48px;
        right: auto;
        bottom: -60px
      }
    }
  }
}

.questions_container {
  background-color: $main_colour;
  padding-top: 81px;
  padding-bottom: 81px;
  margin-bottom: 103px;
}
.question_block {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0px;
  }
  transition: 0.3s ease;
  padding-left: 114px;
  padding-right: 114px;
  min-height: 113px;
  background: #F4CDE0;
  /* secondary_colour_3 */
  box-sizing: border-box;
  border: 5px solid #F9F1E6;
  border-radius: 100px;
  .question {
    margin-top: 30px;
    @extend %h2;
  }
  .answer {
    display: none;
    padding-bottom: 47px;
    padding-top: 30px;
    @extend %body1;
  }
  position: relative;
  svg {
    position: absolute;
    top: 43px;
    right: 114px;
    transition: 0.3s ease-in-out;
    path {
      transition: 0.3s ease-in-out;
    }
  }
  &.active {
    border-color: #EE2D2A;
    svg {
      transform: rotate(180deg);
      top: 38px;
      path {
        &:nth-child(2) {
          fill: $secondary_color_2;
        }
      }
    }
  }
}


.account_title {
  @extend %h1;
  margin-top: 131px;
}

.acc-header {
  margin-top: 30px;
  margin-bottom: 56px;
  li {
    display: inline-block;
    margin-right: 70px;
    button {
      margin: 0px;
      padding: 0px;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      @extend %h2;
      color: $main_colour;
    }
    .current {
      color: $accent_colour;
      button {
        color: $accent_colour;
      }
    }
  }
}


.page-id-12 {

  // ul.acc-header {
  //   display: flex;

  //   li {
  //     margin-right: 100px;
  //     h4 {
  //       // color: $secondary-text;
  //       // transition: $transition;

  //       &:hover {
  //         // color: $main_color;
  //         // transition: $transition;
  //       }
  //     }
  //     .current {
  //       h4 {
  //         // color: $main_color;
  //         // transition: $transition;
  //       }
  //     }
  //   }
  // }

  form {
    ul {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .wppb-form-field {
    opacity: 0;
    height: 0;
    padding: 0;
    max-width: 0;
    transition: 0.3s ease-in;
    margin-top: 0;

    input,
    label {
      width: 100%;
    }

    input {
      padding: 0;
    }

    &.show {
      margin-left: 15px;
      margin-right: 15px;

      &:not(.wppb-heading) {
        display: inline-block;
        // margin-top: 16px;
        width: calc(50% - 30px);
        opacity: 1;
        height: auto;
        // padding: 15px 0;
        max-width: unset;
      }

      input {
        // padding: 9px 23px;
      }
    }
  }
  .wppb-heading {
    display: none;
    width: 0;
  }

  li {
  }

  input[type=text],
  input[type=email],
  input[type=password] {
    outline: none;
      resize: none;
      width: 100%;
      max-width: 434px;
      height: 60px;
      box-sizing: border-box;
      background: #F9F1E6;
      /* secondary_colour */
      border: 5px solid #73C098;
      border-radius: 50px;
      padding-left: 25px;
      @extend %body1;
      margin-bottom: 20px!important;
  }

  input[type=submit] {
    font-family: 'DM Serif Display', serif;
    width: 180px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F9F1E6;
    /* main_colour */

    border: 5px solid #FBBC14;
    border-radius: 200px;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 140px;
  }
}


.account-page {
  .col-lg-10 {
    position: relative;
    .svg_abs_first {
      position: absolute;
      top: 105px;
      right: 70px;
      &+svg {
        top: 213px;
        left: -100px;
        position: absolute;
        &+svg {
          bottom: 80px;
          right: 0;
          position: absolute;
        }
      }
    }
    
  }

  .password-line svg, .show-hide svg {
    top: -20px;
  }
}

.doc_title {
  margin-top: 131px;
  @extend %h1;
  position: relative;
  svg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.doc_content {
  margin-top: 50px;
  margin-bottom: 73px;
  @extend %body1;
  p,ul,ol {
    margin: 10px 0px;
  }
  h2 {
    margin: 25px 0px;
    @extend %h2;
  }
  ul,ol {
    margin-left: 30px;
    li {
      margin: 10px 0px;
    }
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }

  a {
    color: $main_colour;
  }
}


.order_container {
  position: relative;
  svg {
    &:first-child {
      position: absolute;
      top: 68px;
      left: -130px;
    }
    &:nth-child(2) {
      position: absolute;
      top: 120px;
      right: -50px;
    }
  }

  .order_title {
    @extend %h1;
    margin-top: 131px;
    margin-bottom: 40px;
  }


  .for_order_form {
    max-width: 887px;
    margin-bottom: 103px;

    input[type=submit] {
      font-family: 'DM Serif Display', serif;
      width: 180px;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F9F1E6;
      /* main_colour */

      border: 5px solid #FBBC14;
      border-radius: 200px;
      font-size: 20px;
      line-height: 22px;

    }
    label {
      @extend %body1;
      input {
        margin-top: 6px;
      }
    }
    .two_input {
      display: flex;
      justify-content: space-between;
      label {
        max-width: 434px;
      }
      input {
        display: block;
        max-width: 434px;
      }
    }

    input[type=text],
    input[type=email],
    input[type=password],
    textarea {
      outline: none;
      resize: none;
      width: 100%;
      // max-width: 434px;
      height: 60px;
      box-sizing: border-box;
      background: #F9F1E6;
      /* secondary_colour */
      border: 5px solid #73C098;
      border-radius: 50px;
      padding-left: 25px;
      @extend %body1;
    }

    textarea {
      height: 90px;
      padding-top: 10px;
    }

    .label_subject,
    .label_message {
      display: block;
      width: 100%;
      margin-top: 22px;
      margin-bottom: 20px;
      display: block;
    }
    .label_message {
      textarea {
        margin-top: 5px;
      }
    }

    .label_subject {
      margin-bottom: 0px;
    }

    .wpcf7-form-control {
      .wpcf7-list-item {
        label {
          position: relative;
          cursor: pointer;
          input {
            position: absolute;
            left: -9999999999px;
            &:checked {
              &+span {
                &::after {
                  opacity: 1;
                }
              }
            }
          }
          .wpcf7-list-item-label {
            padding-left: 38px;
            &::before {
              content: '';
              display: block;
              width: 28px;
              height: 28px;
              background: #F9F1E6;
              border-radius: 5px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 0;
            }
            &::after {
              content: '';
              display: block;
              width: 20px;
              height: 20px;
              position: absolute;
              left: 4px;
              top: 50%;
              transform: translateY(-50%);
              background-color: $main_colour;
              border-radius: 5px;
              opacity: 0;
            }
          }
        }
        &.first {
          margin-left: 0px;
        }
      }
    }

  }
}

.product-thumbnail {
  display: none;
  
}
.woocommerce {
  margin-top: 100px;
  margin-bottom: 100px;
}
#add_payment_method .wc-proceed-to-checkout a.checkout-button, .woocommerce-cart .wc-proceed-to-checkout a.checkout-button, .woocommerce-checkout .wc-proceed-to-checkout a.checkout-button {
  display: flex!important;
  justify-content: center;
  align-items: center;
  font-family: 'DM Serif Display', serif;
      width: 280px!important;
      height: 54px!important;
      display: flex!important;
      justify-content: center;
      align-items: center;
      background: #F9F1E6!important;
      /* main_colour */

      border: 5px solid #FBBC14!important;
      border-radius: 200px!important;
      font-size: 20px!important;
      line-height: 22px!important;
      color: $accent_colour!important;
}



.--animation_easy-top {
	animation: ease-top 1s ease;
	position: relative;
	// overflow: hidden;
	&:before {
		content: "";
		position: absolute;
		width: 0;
		height: 100%;
		background: $background_colour;
		animation: h-to-right 2s ease;
  }
}
@keyframes h-to-right {
  0%, 100% {
    width: 100%;
  }
  0% {
    -webkit-clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
  }
  100% {
    -webkit-clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  }
}
@keyframes fade-in {
  0%, 50% {
    opacity: 0;
  }
}
@keyframes ease-top {
  0%, 10% {
    opacity: 0;
  }
  10% {
    transform: translate(0, 30px);
  }
}





$select-color: #000;
$select-background: transparent;
$select-width: 100%;
$select-height: 64px;
$font_color: red; 

.current-menu-item {
  font-weight: bold;
}

.select {
  // margin-top: 22px;
    // border-radius: 30px;
    // margin-top: 12px;
    // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border: none!important;
    margin-bottom: 15px;
}

.select-hidden {
    display: none;
    visibility: hidden;
    padding-right: 10px;
  }
  .select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 16px;
    color: $select-color;
    width: $select-width;
    max-width: 100%;
    height: $select-height;
    border: 1px solid $font_color;
    box-sizing: border-box;
  }
  .select-styled {
      box-sizing: border-box!important;
      border-radius: 0px;
    // border: 0px solid transparent;
    position: absolute; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $select-background;
    padding: 20px 15px;
    transition: all 0.2s ease-in;
    // background-color: red;
    background: #F9F1E6;
/* secondary_colour */

border: 5px solid #73C098;
border-radius: 50px;

    &:after {
      content:"";
    //   width: 0;
    //   height: 0;
    //   border: 7px solid transparent;
    //   border-color: $select-color transparent transparent transparent;
      position: absolute;
      transition: 0.3s;
    //   top: 16px;
    //   right: 10px;
      display: block;
      width: 19px;
      height: 14px;
      background-image: url('../img/fa_select.svg');
      background-size: cover;
      top: 22px;
      right: 20px;
    }
    &:hover {
      // background-color: #fff;
    }
    &:active, &.active {
        // border: 2px solid #FFD575;
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  
  .select-options {


    display: none; 
    position: absolute;
    top: 110%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #FFFFF6;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    // border: 1px solid #DFDFDF;
    box-sizing: border-box;
    li {
      margin: 0;
      padding: 12px 0;
      text-indent: 15px;
      border-top: 1px solid darken($select-background, 10);
      transition: all 0.15s ease-in;
      &:hover {
        color: #000000;
        background: #F0F0F0;
      }
      &[rel="hide"] {
        display: none;
      }
    }
  }



  .second_block a,
  .slider_block .slide_content .for_text a {
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: #EE2D2A;
    text-decoration: none;
    color: #fff;
    }
  }


  .what_we_do a,
  .solid_reasons a,
  .pre_footer a,
  .products_container .for_products .product a,
  .order_container .for_order_form input[type=submit],
  .contact_banner .for_contact_form input[type=submit] {
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: $main_colour;
      // color: #fff;
    }
  }



  .for_loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $background_colour;
    z-index: 3333;
    display: flex;
    justify-content: center;
    align-items: center;
    .loader {
      /* the colors */
      --c1: #73C098;
      --c2: #FBBC14;
      --c3: #EE2D2A;
      --c4: #03412C;
      /**/
      
      width: 180px; /* control the size */
      aspect-ratio: 8/5;
      --_g: no-repeat radial-gradient(#000 68%,#0000 71%);
      -webkit-mask: var(--_g),var(--_g),var(--_g);
      -webkit-mask-size: 25% 40%;
      background: 
        conic-gradient(var(--c1) 50%,var(--c2) 0) no-repeat,
        conic-gradient(var(--c3) 50%,var(--c4) 0) no-repeat;
      background-size: 200% 50%;
      animation: 
        back 4s infinite steps(1),
        load 2s infinite;
    }
    
    @keyframes load {
      0%    {-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 0%  }
      16.67%{-webkit-mask-position: 0% 100%,50% 0%  ,100% 0%  }
      33.33%{-webkit-mask-position: 0% 100%,50% 100%,100% 0%  }
      50%   {-webkit-mask-position: 0% 100%,50% 100%,100% 100%}
      66.67%{-webkit-mask-position: 0% 0%  ,50% 100%,100% 100%}
      83.33%{-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 100%}
      100%  {-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 0%  }
    }
    @keyframes back {
      0%,
      100%{background-position: 0%   0%,0%   100%}
      25% {background-position: 100% 0%,0%   100%}
      50% {background-position: 100% 0%,100% 100%}
      75% {background-position: 0%   0%,100% 100%}
    }

  }
// Always in the end



.error_page,
.thank_page {
  text-align: center;
  p {
    @extend %h2;
    margin-bottom: 44px;
    color: $secondary_color_2;
  }
  a {
    margin-bottom: 110px;
    display: inline-block;
    font-family: 'DM Serif Display', serif;
    padding: 16px 58px;
    border: 5px solid $main_colour;
    border-radius: 200px;
    background-color: $secondary_colour_3;
    font-size: 20px;
    line-height: 22px;
    color: $accent_colour;
    text-decoration: none;
    &:hover {
      background-color: $main_colour;
      color: #fff;
    }
  }
}
@import "_media.scss";
// ___________________